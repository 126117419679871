html {
    font-family: sans-serif;
}

body {
    color: rgb(221, 221, 221);
    margin: 0;
}

header {
    background-color: #171a21;
    padding-top: 2em;
    height: 5em;
}

h1 {
    text-align: center;
    color: #c6c4c2;
    margin: 0;
}

h2 {
}

section {
    display: flex;
    background-color: #1b2838;
}

article {
    width: 54%;
    height: 50%;
    margin: auto;
    padding: 10px;
    background-color: #2f343e;
    box-shadow: 0px 0px 5px black;
}

.search-container {
    text-align: center;
    margin: auto;
}

#search-bar {
    padding-left: 10px;
    margin-top: 50px;
    width: 60%;
    height: 30px;
    border-radius: 6px;
    background-color: #c6c4c2;
}

/* #search-results {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.95);
} */

#search-icon {
    width: 2%;
    height: 100%;
}

.subtitle {
    float: left;
    text-align: left;
    padding-top: 20px;
}

.subtitle h2 {
    color: #67c1f5;
    margin: 0;
}

.subtitle h3 {
    color: #c6d4df;
    margin: 0;
}

.game-image-container {
    float: right;
    padding-top: 20px;
}

.search-filters {
    width: 51.7%;
    margin: auto;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0.5em 0;
    background-color: #0A0F15;
}

.search-filters select {
    margin-right: 3em;
}

#achievements-container {
    margin: auto;
    width: 94%;
    background-color: #1b2838;
}

@media screen and (max-width: 856px) {
    article {
        width: 100%;
    }

    .game-image-container {
        width: 100%;
        clear: both;
    }

    .subtitle {
        width: 100%;
        text-align: center;
    }

    .search-filters {
        width: 100%;
    }

    .achievements-container {
        width: 100%;
        padding: 0;
        background-color: #111923;
    }
}