body,
textarea {
  font-family: Arial, Helvetica, Verdana, sans-serif;
  background-color: #1b2838;
}

#search-results {
  position: absolute;
  left: 30vw;
  width: 40vw;
  margin-top: 0.1em;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 100;
}

.search-result > img {
  width: 40%;
  margin-top: 1em;
}

.achieveRow {
  display: flex;
  flex-direction: row;
  width: 55%;
  margin: auto;
  padding: 0.5em 1em 0.5em 1em;
  background-color: #111923;
}

.achieveTileContainer {
  width: 70vw;
  display: flex;
  margin: auto;
  justify-content: center;
  flex-wrap: wrap;
}

.achieveRowTile {
  background-color: #111923;
}

.achieveImgHolder {
  padding-right: 0.2em;
}

.achieveImgHolder > img {
  width: 5vw;
  height:5vw;
}

.achieveImgHolderTile {
  width: 80px;
  height: 80px;
  padding: 0.8em;
  margin-bottom: 5em;
  text-align: center;
}

.achieveImgHolderTile > img {
  width: 5vw;
  height: 5vw;
}

.achieveTxtHolder {
  position: relative;
  border: 1px solid #17435c;
  padding: 1px;
  overflow: hidden;
}

.achieveFill {
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 2px;
  z-index: 10;
  background: #17435c;
}

.achieveTxt {
  position: relative;
  width: 100vw;
  padding: 6px 6px 0px 6px;
  z-index: 40;
}

.achievePercent {
  float: right;
  position: relative;
  margin-top: 27px;
  margin-right: 20px;
  color: #66c0f4;
  font-size: 1.4em;
  z-index: 40;
}

.achieveTxt > h3 {
  margin: 5px 5px 0px 5px;
  font-weight: normal;
  font-size: 1.5em;
  color: #c6d4df;
}

.achieveTxt > h5 {
  margin: 0px 5px 5px 5px;
  font-weight: normal;
  font-size: 1.2em;
  color: #8f98a0;
}

@media screen and (max-width: 1000px) {
  header {
    font-size: 2em;
  }

  article {
    width: 100vw;
    font-size: 2.5em;
  }

  .game-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subtitle {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .game-image-container > img {
    width: 50vw;
  }

  #search-bar {
    height: 1.5em;
    width: 80vw;
    font-size: 1em;
  }

  .search-filters {
    width: 100vw;
  }

  .search-filters > label {
    font-size: 2.5em;
  }

  #filter-select {
    font-size: 2em;
    text-align: center;
    margin-right: 1em;
  }

  #filter-select > option {
    font-size: 0.6em;
  }

  #tile-view-checkbox {
    transform: scale(3);
    margin-left: 1.5em;
  }

  #search-results {
    position: absolute;
    left: 15vw;
    width: 70vw;
    margin-top: 0.1em;
    z-index: 100;
  }

  .search-result {
    width: 100%;
  }

  .search-result > img {
    margin-top: 0.5em;
    width: 70%;
  }

  .achieveRow {
    width: 100vw;
    padding: 1em 0 0 0;
  }

  .achieveImgHolder > img {
    width: 15vw;
    height: 15vw;
  }

  .achieveTxtHolder {
    height: 7vh;
  }

  .achieveTxt > h3 {
    font-size: 2.5em;
  }

  .achieveTxt > h5 {
    font-size: 1.8em;
  }

  .achievePercent {
    font-size: 3em;
    padding-left: 1em;
  }

  .achieveTileContainer {
    width: 100vw;
    background-color: #111923;
  }

  .achieveImgHolderTile {
    width: 20vw;
    height: 28vw;
    margin-bottom: 2em;
    overflow: hidden;
  }

  .achieveImgHolderTile > img {
    width: 20vw;
    height: 20vw;
  }

  .achieveTileName {
    font-size: 2em;
  }

  .pagination {
    font-size: 3.5em;
  }

  .circle-loader {
    border: 24px solid #d4d4d4;
    border-top: 24px solid #3292c9;
    width: 20vw;
    height: 20vw;
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fade-in {
  opacity: 1;
  animation: fadeIn 0.5s linear;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 15px auto;
  padding: 0;
  list-style: none;
  outline: none;
}

.pagination > .active > a {
  background-color: #47ccde;
  border-color: #47ccde;
  color: #fff;
}

.pagination > li > a {
  border: 1px solid #47ccde;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #47ccde;
  border-color: #47ccde;
  outline: none;
}

.pagination > li > a,
.pagination > li > span {
  color: #47ccde;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.circle-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 15px solid #d4d4d4;
  border-radius: 50%;
  border-top: 15px solid #3292c9;
  width: 8vw;
  height: 8vw;
  margin: auto auto;
  -webkit-animation: spin 1.2s linear infinite; /* Safari */
  animation: spin 1.2s linear infinite;
}
