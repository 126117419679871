.modal {
  position: fixed;
  z-index: 1;
  top: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  opacity: 1;
  animation: fadeIn 0.3s linear;
}

.modal-content {
  position: fixed;
  left: 0;
  right: 0;
  top: 30%;
  width: 80%;
  color: #c6d4df;
  background-color: rgb(23, 67, 92, 0.9);
  border: 1px solid #c6d4df;
  margin: auto;
}

.modal-content h2 {
  margin: 0.5em 0 0.5em 0;
  color: #c6d4df;
}

.modal-content h4 {
  color: #a9b6c0;
  padding: 0.4em;
  margin: 0.5em 0 0.5em 0;
}

.percentBar {
  position: relative;
  border: 2px solid #67c1f5;
  padding: 4px;
  overflow: hidden;
  width: 50%;
  height: 1.5em;
  margin: auto;
}

.percentBarFill {
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 2px;
  background: #67c1f5;
  opacity: 1;
  animation: scale 1s linear;
}

.percentText {
  position: relative;
  float: right;
  padding-top: 2px;
  color: #67c1f5;
}

.icon {
  margin: 0.5em 0em 0em 2em;
}

.close {
  float: right;
  font-size: 1.8em;
}

@media screen and (max-width: 1000px) {
  .modal-content {
    width: 100vw;
    height: 50vh;
    top: 25vh;
    font-size: 3em;
  }

  .icon {
    width: 30vw;
    height: 30vw;
  }

  .percentBar {
    width: 100vw;
    height: 4.5vh;
  }

  .percentText {
    font-size: 1.5em;
    padding-right: 0.3em;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
